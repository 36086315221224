<template>
  <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item active">Holidays</li>
      </ol>
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row align-items-center">
        <div class="col-5">
          <h2 class="title2">Holidays</h2>
        </div>
      </div>
      <div class="t-layout">
        <div class="row">
          <div class="col-md-12">
            <div v-if="this.$store.state.loader">
              <loader
                object="#f74b3d"
                color1="#ffffff"
                color2="#17fd3d"
                size="5"
                speed="2"
                bg="#343a40"
                objectbg="#999793"
                opacity="80"
                disableScrolling="false"
                name="spinning"
              ></loader>
            </div>
            <div v-else class="card">
              <div class="card-header">
                <flash-message class="myCustomClass"></flash-message>
              </div>
              <div class="card-body">
                <div class="custom-pagination">
                  <div class="row">
                    <div class="col-md-3">
                      <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search data"
                      ></b-form-input>
                    </div>
                    <div class="col-md-9 tx-right">
                      <div class="page-number-input custom-box">
                        <label>Go to Page:</label>
                        <b-form-input
                          class="page-number"
                          v-model="currentPage"
                          type="number"
                          min="1"
                          :max="
                            holidaysData.length > 0
                              ? Math.ceil(holidaysData.length / perPage)
                              : 1
                          "
                        ></b-form-input>
                      </div>
                      <div class="pagination-wrapper custom-box">
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="holidaysData.length"
                          :per-page="perPage"
                          aria-controls="my-table"
                        ></b-pagination>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <b-table
                    class="table table-custom"
                    :items="holidaysData"
                    :fields="fields"
                    :per-page="perPage"
                    :current-page="currentPage"
                    responsive="sm"
                    :sort-compare="mySortCompare"
                    :sort-desc.sync="sortDesc"
                    :sort-by.sync="sortBy"
                    show-empty
                    :filter="filter"
                    sticky-header
                  >
                    <template #head()="{ label, field: { key, sortable } }">
                      {{ label }}
                      <template v-if="sortable">
                        <template>
                          <b-img
                            v-if="sortBy !== key"
                            :src="sortIconNeutral"
                            style="width: 0.65rem; height: 1rem"
                          ></b-img>
                          <span title="Sort By ascending" v-else-if="sortDesc"
                            ><b-img
                              :src="sortIconAsc"
                              style="width: 0.65rem; height: 1rem"
                            ></b-img
                          ></span>
                          <span v-else title="Sort By descending"
                            ><b-img
                              :src="sortIconDesc"
                              style="width: 0.65rem; height: 1rem"
                            ></b-img
                          ></span>
                        </template>
                      </template>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
require("../../axios");
export default {
  data() {
    return {
      currentPage: 1,
      perPage: 15,
      sortIconNeutral:
        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e",
      sortIconDesc:
        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e",
      sortIconAsc:
        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e",
      sortBy: null,
      sortDesc: false,
      filter: null,
      fields: [
        {
          key: "name",
          label: "Holiday Name",
          sortable: true,
        },
        {
          key: "date",
          label: "Date",
          sortable: true,
        },
        {
          key: "day",
          label: "Day",
          sortable: true,
        },
      ],
    };
  },
  created() {
    return this.$store.dispatch("loadHolidaysdata");
  },
  computed: {
    holidaysData() {
      return this.$store.state.holidaysData;
    },
  },
};
</script>
